// @refresh reload
import { Router, useLocation } from "@solidjs/router";
import { FileRoutes } from "@solidjs/start/router";
import { clientOnly } from "@solidjs/start";
import { Layout } from "~/components/layouts/Layout";
import { WireProvider } from "~/wire";
import "./app.css";
import {
  ErrorBoundary,
  Show,
  Suspense,
  createEffect,
  on,
  onMount,
} from "solid-js";
import { AuthRedirectTrigger } from "~/domains/identity/components";
import { MetaProvider } from "~/lib/solid-meta";
import { SEOHeaders } from "./components/SEOHeaders";
import { captureException } from "@repo/observability";
import { StButton } from "./components/buttons";
import { TbReload } from "solid-icons/tb";
import { stAnalytics } from "@repo/analytics";

const DOMThirdPartyScripts = clientOnly(
  () => import("./components/DOMThirdPartyScripts"),
);

export default function App() {
  const Err = (props: { err: Error }) => {
    captureException(props.err);
    console.error("Error:", props.err);
    return (
      <div class="fixed inset-0 bg-white dark:bg-indigo-1100 flex flex-col items-center justify-center text-center gap-4 animate-fade-in">
        <h2 class="text-black dark:text-white text-3xl font-semibold">
          Oops, something went wrong!
        </h2>
        <p class="text-slate-800 dark:text-indigo-200">
          We’ve informed our engineers so they can look into it.
        </p>

        <StButton
          size="lg"
          onClick={() => {
            // biome-ignore lint/correctness/noSelfAssign: <explanation>
            window.location.href = window.location.href;
          }}
          icon={TbReload}
        >
          Reload
        </StButton>
        <Show
          when={
            RUNNING_ENV !== "production" &&
            props.err &&
            props.err instanceof Error
          }
        >
          <div class="px-20 w-screen">
            <div class="select-all rounded mt-10 max-h-96 mx-auto max-w-screen-4xl overflow-x-auto border dark:border-indigo-800 dark:bg-indigo-950  dark:text-white">
              <pre class="w-full text-left p-4 text-2xs">
                <code>{props.err?.stack}</code>
              </pre>
            </div>
          </div>
        </Show>
      </div>
    );
  };
  return (
    <Suspense>
      <MetaProvider>
        {/* Default opengraph headers */}
        <SEOHeaders
          title="Storytell.ai | Turn Data into Business Intelligence with AI™"
          keywords={["storytell", "AI"]}
          description="Storytell.ai makes AI useful & secure for businesses. Turn enterprise data into actionable insights, boost team productivity, and drive smarter decisions."
          image="https://cdn.prod.website-files.com/65a80909e3cbfe6b51a9c764/65c52b7c116e90f44fc0ea58_Open%20Graph2.jpg"
        />

        {/* Routing setup */}
        <Router
          root={(props) => (
            <ErrorBoundary fallback={(err) => <Err err={err} />}>
              <PageViewTracker />
              <WireProvider>
                <DOMThirdPartyScripts />
                <AuthRedirectTrigger />
                <Layout>{props.children}</Layout>
                {/*<Bug />*/}
              </WireProvider>
            </ErrorBoundary>
          )}
        >
          <FileRoutes />
        </Router>
      </MetaProvider>
    </Suspense>
  );
}

const PageViewTracker = () => {
  const location = useLocation();
  createEffect(
    on(
      () => [location.pathname, location.search, location.hash],
      () => {
        stAnalytics.page();
      },
    ),
  );
  return undefined;
};
